.item {
  display: block;
  color: #4b5563;
  border-radius: 0.5rem;
}

.item:hover {
  color: #4b5563;
  background-color: rgba(107, 114, 128, 0.1);
}

.item.selected {
  font-weight: 500;
  color: #60a5fa;
}

:global(.dark) .item {
  color: #d1d5db;
}

:global(.dark) .item:hover {
  color: #d1d5db;
}

:global(.dark) .item.selected {
  color: #60a5fa;
}
